<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="row">
      <div class="col-xl-8 col-lg-12 col-md-12">
        <div class="card mb-0">
          <div class="card-header">
            <router-link to="/profile/education/me" type="button">
              <i class="dropdown-icon si si-arrow-left"></i>
            </router-link>
            <h3 class="card-title">{{ title }}</h3>
            <div class="card-options">
              <router-link
                :to="
                  '/profile/education/participant/' +
                  $route.params.id +
                  '/message'
                "
                type="button"
                class="btn btn-primary"
              >
                Eğitmene Sor
              </router-link>
            </div>
          </div>
          <div class="card-body" style="margin: -24px">
            <VideoPlayer :video="selectedVideo" />

            <div class="wideget-user-tab">
              <div class="tab-menu-heading">
                <div class="tabs-menu1">
                  <ul class="nav mt-4">
                    <li class="">
                      <a href="#tab-51" class="active" data-bs-toggle="tab"
                        >Video Bilgileri</a
                      >
                    </li>
                    <li>
                      <a
                        href="#tab-61"
                        @click="getMemo"
                        data-bs-toggle="tab"
                        class=""
                        >Notlar</a
                      >
                    </li>
                    <li>
                      <a href="#tab-71" data-bs-toggle="tab" class=""
                        >Soru & Cevap</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-body border-0">
              <div class="tab-content">
                <div class="tab-pane active" id="tab-51">
                  <div id="profile-log-switch" v-if="videoTitle != ''">
                    <div class="table-responsive">
                      <table class="table row table-borderless">
                        <tbody class="col-lg-12 col-xl-12 p-0">
                          <tr>
                            <td>
                              <strong>Video Başlığı :</strong>
                              {{ videoTitle.title }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody class="col-lg-12 col-xl-12 p-0">
                          <tr>
                            <td>
                              <strong>Süresi :</strong>{{ videoTitle.duration }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row profie-img">
                      <div class="col-md-12 text-justify">
                        <div class="media-heading">
                          <h5><strong>Video Açıklaması</strong></h5>
                        </div>
                        <p>
                          {{ videoTitle.content }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <template v-else
                    ><div style="text-align: center; font-weight: bolder">
                      Henüz bir video seçilmedi
                    </div></template
                  >
                </div>
                <div class="tab-pane" id="tab-61">
                  <div class="row" v-if="videoTitle != ''">
                    <div v-for="(item, i) in memos" :key="i" class="memos">
                      <div class="time" @click="jump(item.time)">
                        {{ convertTime(item.time) }}
                      </div>
                      <div
                        class="remove"
                        @click="removeMemo(item.id)"
                        title="Sil"
                      >
                        <i class="fa fa-trash-o"></i>
                      </div>
                      <div class="content">{{ item.content }}</div>
                    </div>
                    <template v-if="memoLoader"
                      ><b style="text-align: center"
                        >Yükleniyor, lütfen bekleyin...</b
                      ></template
                    >
                    <div class="" v-if="!memoLoader">
                      <label>Not Girin:</label>
                      <textarea
                        @click="pause"
                        class="form-control"
                        v-model="memo"
                      ></textarea>
                      <button
                        class="btn btn-primary mt-2 float-right"
                        @click="addMemo"
                      >
                        Notu Kaydet
                      </button>
                    </div>
                  </div>
                  <template v-else
                    ><div style="text-align: center; font-weight: bolder">
                      Henüz bir video seçilmedi
                    </div></template
                  >
                </div>
                <div class="tab-pane" id="tab-71">
                  <div class="wideget-user-followers">
                    <div class="input-group">
                      <input
                        style="border: 1px solid #bb7494"
                        type="text"
                        class="form-control"
                        v-model="search"
                        placeholder="Eğitimde yada videoda soru ara"
                      />
                      <button class="btn btn-primary" @click="getQuestion">
                        Ara
                      </button>
                    </div>
                    <div class="form-group mt-2">
                      <div
                        class="d-md-flex ad-post-details"
                        style="margin: 0 auto; width: fit-content"
                      >
                        <label class="custom-control custom-radio mb-2 me-4">
                          <input
                            type="radio"
                            class="custom-control-input"
                            name="radios1"
                            value="option1"
                            id="option1"
                            v-model="type"
                            checked=""
                          />
                          <span class="custom-control-label"
                            ><a class="text-muted">Video için</a></span
                          >
                        </label>
                        <label class="custom-control custom-radio mb-2">
                          <input
                            type="radio"
                            class="custom-control-input"
                            name="radios1"
                            value="option2"
                            v-model="type"
                          />
                          <span class="custom-control-label"
                            ><a class="text-muted">Eğitim için</a></span
                          >
                        </label>
                      </div>
                      <button
                        class="btn btn-primary w-100"
                        @click="openQuestionBar"
                      >
                        Yeni Soru Sor
                      </button>
                      <div class="form-group" v-if="openQuestionBarObject">
                        <label>Sorunuzu Yazın:</label>
                        <textarea
                          class="form-control"
                          v-model="question"
                          placeholder="sorunuzu bu alana yazınız..."
                        ></textarea>
                        <button
                          class="btn btn-primary mt-3"
                          style="float: right"
                          @click="sendQuestion"
                        >
                          Soruyu Gönder
                        </button>
                      </div>
                      <div class="p-4" v-for="(item, i) in questions" :key="i">
                        <Nested :item="item" :index="i" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-12 col-md-12">
        <div
          class="panel panel-default mb-4 border p-0 rightList"
          style="border: none !important; box-shadow: 4px 4px 5px -3px #c8bebe"
          v-for="(list, i) in modules"
          :key="'list' + i"
        >
          <div class="panel-heading1">
            <ul class="panel-title1">
              <a
                :class="'accordion-toggle ' + (i != 0 ? ' collapsed' : '')"
                data-bs-toggle="collapse"
                :data-parent="'#accordion' + i"
                :href="'#collapse' + i"
                aria-expanded="false"
                >{{ list.title }}</a
              >
            </ul>
          </div>
          <ul
            :id="'collapse' + i"
            :class="'panel-collapse collapse active' + (i == 0 ? ' show' : '')"
            role="tabpanel"
            aria-expanded="false"
          >
            <li
              class="panel panel-default border p-0"
              style="border: none !important"
              v-for="(a, t) in list.subModules"
              :key="'subList' + i + '_' + t"
            >
              <div class="panel-heading1">
                <div
                  :class="
                    'row ' +
                    (selectedIndex == 'subList' + i + '_' + t
                      ? 'activited'
                      : '')
                  "
                  style="border-bottom: 1px solid #fff"
                  @click="select(a, 'subList' + i + '_' + t)"
                >
                  <div class="col-2">
                    <i class="fa fa-play"></i>
                  </div>
                  <div class="col-10">
                    {{ a.title }}
                  </div>
                  <div class="col-7"></div>
                  <div class="col-4 time" v-if="a.video.length>0">
                    {{ convertTime(a.video[0].duration) }}
                  </div>
                  <div class="col-1"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.wideget-user-tab .tabs-menu1 ul li a {
  padding: 17px 30px;
}
.memos {
  padding: 10px;
}
.time {
  background: #a9a9a9;
  color: white;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: fit-content;
  font-size: 10px;
  border-radius: 10px;
}
.memos .time {
  background: #000;
  color: white;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: fit-content;
  font-size: 10px;
  border-radius: 10px;
  position: absolute;
  cursor: pointer;
}
.memos .remove {
  color: white;
  position: absolute;
  right: 30px;
  font-size: 16px;
  background: #bb7494;
  padding: 5px;
  border-radius: 10px;
  width: 35px;
  text-align: center;
  cursor: pointer;
}
.memos .content {
  padding: 10px;
  border-radius: 10px;
  margin-top: 8px;
  border: 1px solid #ddd;
  box-shadow: rgb(129, 129, 129) 0px 5px 5px -5px;
}
.rightList {
  background: #fff;
}
.rightList ul {
  background: #fff;
}
.rightList ul .title {
  background: #c386a2;
  padding: 10px;
  font-weight: bold;
  color: white;
}
.rightList ul li {
  background: rgb(195 134 162 / 30%);
}
.rightList ul li .row {
  width: 100%;
  margin: 0px;
  cursor: pointer;
}
.rightList ul li .row:hover {
  background: #e8e8e8;
}
.activited {
  background: #e8e8e8;
}
.rightList ul li .row .col-10 {
  padding: 5px;
}
.rightList ul li .row .col-2 {
  text-align: center;
  line-height: 40px;
}
.rightList ul li .row .col-2 i {
  padding: 8px;
}
</style>
<script>
import Swal from "sweetalert2";
import VideoPlayer from "../../../../components/VideoPlayer.vue";
export default {
  components: { VideoPlayer },
  created() {
    this.$store
      .dispatch("educationMemberVideosGet", { id: this.$route.params.id })
      .then((value) => {
        this.title = value[0].title;
        document.title = value[0].title;
        this.modules = value[0].modules;
        this.getQuestion();
      });
  },
  data() {
    return {
      title: null,
      modules: [],
      selectedVideo: [],
      videoTitle: [],
      description: "",
      memos: [],
      search: "",
      memoLoader: true,
      questions: [],
      questionLoader: true,
      memo: "",
      type: "option1",
      question: "",
      selectedIndex: "",
      openQuestionBarObject: false,
    };
  },
  watch: {
    videoTitle: function () {
      this.getMemo();
    },
    type: function () {
      this.getQuestion();
    },
  },
  methods: {
    openQuestionBar() {
      if (!this.openQuestionBarObject) {
        this.openQuestionBarObject = true;
      } else {
        this.openQuestionBarObject = false;
      }
    },
    sendQuestion() {
      if (this.question == "") {
        this.$vToastify.warning("Soru alanı boş geçilemez", "Uyarı!");
      } else {
        if (this.type == "option1" && this.selectedVideo.length == 0) {
          this.$vToastify.warning(
            "Video için soru göndermek için bir video seçmen gerek!",
            "Uyarı!"
          );
        } else {
          Swal.fire({
            title: "Soru kaydedilecek!",
            text:
              "Sorunuz " +
              (this.type == "option1"
                ? "seçili video için"
                : "izlediğiniz eğitim için") +
              " değerlendirilip kaydedilecektir, devam etmek istiyor musun?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Devam",
            cancelButtonText: "Vazgeç",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store
                .dispatch("educationQuestionAdd", {
                  education_id: this.$route.params.id,
                  video_id:
                    this.selectedVideo.length > 0
                      ? this.videoTitle.video[0].id
                      : 0,
                  parent_id: 0,
                  content: this.question,
                })
                .then((value) => {
                  this.question = "";
                  this.getQuestion();
                });
            }
          });
        }
      }
    },
    jump(time) {
      let video = document.getElementById("aks-video");
      video.currentTime = time;
    },
    pause() {
      let video = document.getElementById("aks-video");
      video.pause();
    },
    convertTime(time) {
      var sec_num = parseInt(time, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + ":" + minutes + ":" + seconds;
    },
    getQuestion() {
      this.questions = [];
      this.questionLoader = true;
      this.$store
        .dispatch("educationQuestionList", {
          education_id: this.$route.params.id,
          video_id:
            this.videoTitle != null && this.type == "option1"
              ? this.videoTitle.video[0].id
              : 0,
          search: this.search,
        })
        .then((value) => {
          this.questions = value;
          this.questionLoader = false;
        });
    },
    getMemo() {
      this.memos = [];
      this.memoLoader = true;
      if (this.videoTitle.length > 0 || this.videoTitle != "") {
        this.$store
          .dispatch("educationVideoMemoList", {
            education_id: this.$route.params.id,
            video_id: this.videoTitle.video[0].id,
          })
          .then((value) => {
            this.memos = value;
            this.memoLoader = false;
          });
      }
    },
    addMemo() {
      if (this.videoTitle.length > 0 || this.videoTitle != "") {
        var video = document.getElementById("aks-video");
        let time = parseInt(video.currentTime, 10);
        this.$store
          .dispatch("educationVideoMemoAdd", {
            education_id: this.$route.params.id,
            video_id: this.videoTitle.video[0].id,
            content: this.memo,
            time: time,
          })
          .then((value) => {
            this.memo = "";
            this.getMemo();
          });
      } else {
        alert("sda");
      }
    },
    removeMemo(id) {
      Swal.fire({
        title: "Video notunu silmek istediğine emin misin?",
        text: "Bu işlem sonrası videoya ait not silinecek, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("educationVideoMemoRemove", {
              education_id: this.$route.params.id,
              video_id: this.videoTitle.video[0].id,
              id: id,
            })
            .then((value) => {
              this.memo = "";
              this.getMemo();
            });
        }
      });
    },
    select(s, t) {
      this.selectedIndex = t;
      if (s.video.length > 0) {
        this.selectedVideo = s.video[0];
      }
      this.videoTitle = s;

      $("html, body").animate(
        {
          scrollTop: $("#content-app").offset().top - 60,
        },
        0
      );
    },
  },
};
</script>